
  import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import InspiniaIbox from '@/components/InspiniaIbox.vue';
  import HipsterInput from '@/components/HipsterInput.vue';
  import { DELETE_TROPHY, FETCH_USER, UPDATE_TROPHY } from '@/store/users';
  import { Trophy } from '@/types/entities';
  import isEqual from 'lodash/isEqual';
  import { TrophyRank } from '@/types/enums';

  @Component({
    components: { HipsterInput, InspiniaIbox, HipsterSelector },
    mixins: [ validationMixin ],
    validations() {
      return {
        updatedTrophy: {
          name: { required },
          rank: { required }
        }
      };
    },
    data() {
      return {
        updatedTrophy: {}
      };
    }
  })
  export default class TrophyEdit extends Mixins() {
    @Prop() trophy: Trophy;

    public created(): void {
      this.setUpdatedTrophy();
    }

    @Watch('trophy')
    public setUpdatedTrophy(): void {
      this.$data.updatedTrophy = this.trophy;
    }

    public get isChanged(): boolean {
      return !isEqual(this.trophy, this.$data.updatedTrophy);
    }

    public get trophyRank() {
      return [
        { name: String(TrophyRank.FIRST), value: TrophyRank.FIRST },
        { name: String(TrophyRank.SECOND), value: TrophyRank.SECOND },
        { name: String(TrophyRank.THIRD), value: TrophyRank.THIRD }
      ];
    }

    public trophyUpdated(trophy: Trophy): void {
      this.$v.updatedTrophy.$touch();

      this.$data.updatedTrophy = {
        ...this.$data.updatedTrophy,
        ...trophy
      };
    }

    public updateClicked(): void {
      this.$store.dispatch(UPDATE_TROPHY, this.$data.updatedTrophy)
        .then(() => this.$store.dispatch(FETCH_USER, this.$route.params.id));
    }

    public deleteClicked(): void {
      this.$store.dispatch(DELETE_TROPHY, this.trophy.id)
        .then(() => this.$store.dispatch(FETCH_USER, this.$route.params.id));
    }
  }
