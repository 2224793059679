
  import { Component, Mixins } from 'vue-property-decorator';
  import InspiniaIbox from '@/components/InspiniaIbox.vue';
  import {
    BLOCK_USER,
    FETCH_USER,
    RESTORE_USER,
    SET_USER_ITEM_VALUE,
    UPDATE_USER,
    TURN_OFF_BOT,
    TURN_ON_BOT,
    CREATE_TROPHY
  } from '@/store/users';
  import ImagesMixin from '@/mixins/images.mixin';
  import { Mutation } from 'vuex-class';
  import { NotStrictUser, Trophy, User } from '@/types/entities';
  import HipsterInput from '@/components/HipsterInput.vue';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { roles, TrophyRank } from '@/types/enums';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import TrophyEdit from '@/components/TrophyEdit.vue';

  @Component({
    components: { HipsterInput, InspiniaIbox, HipsterSelector, TrophyEdit },
    mixins: [ validationMixin ],
    validations() {
      return {
        user: {
          balance: { required }
        },
        trophy: {
          name: { required },
          rank: { required }
        }
      };
    },
    data() {
      return {
        trophy: {
          name: null,
          rank: null,
          description: null
        }
      };
    }
  })
  export default class UsersDetails extends Mixins(ImagesMixin) {
    @Mutation(SET_USER_ITEM_VALUE) setUserValue: (data: NotStrictUser) => void;

    created() {
      this.$store.dispatch(FETCH_USER, this.$route.params.id)
        .then(() => this.$store.commit('setTitle', this.user.name));
    }

    get userImage(): string {
      if (this.user.image) {
        return this.getOriginalImageUrl(this.user.image.link);
      }

      return '/images/mock_user.png';
    }

    get trophyRank() {
      return [
        { name: String(TrophyRank.FIRST), value: TrophyRank.FIRST },
        { name: String(TrophyRank.SECOND), value: TrophyRank.SECOND },
        { name: String(TrophyRank.THIRD), value: TrophyRank.THIRD }
      ];
    }

    get roles() {
      return {
        user: roles.user,
        bot: roles.bot
      };
    }

    get user(): User {
      return this.$store.state.users.item;
    }

    get isBot(): boolean {
      return this.user.role_id === roles.bot;
    }

    public save() {
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.$store.dispatch(UPDATE_USER)
        .then(() => this.$router.push({ name: 'app.users.list' }));
    }

    public remove() {
      this.$store.dispatch(BLOCK_USER)
        .then(() => this.$store.dispatch(FETCH_USER, this.$route.params.id));
    }

    public turnOff() {
      this.$store.dispatch(TURN_OFF_BOT)
        .then(() => this.$store.dispatch(FETCH_USER, this.$route.params.id));
    }

    public createTrophy(): void {
      if (this.$v.trophy.$invalid) {
        return;
      }

      this.$store.dispatch(CREATE_TROPHY, {
        ...this.$data.trophy,
        user_id: this.$route.params.id
      })
        .then(() => {
          this.$data.trophy = {
            name: null,
            rank: null,
            description: null
          };
          this.$store.dispatch(FETCH_USER, this.$route.params.id);
        });
    }

    public trophyUpdated(trophy: Trophy): void {
      this.$v.trophy.$touch();

      this.$data.trophy = {
        ...this.$data.trophy,
        ...trophy
      };
    }

    public turnOn() {
      this.$store.dispatch(TURN_ON_BOT)
        .then(() => this.$store.dispatch(FETCH_USER, this.$route.params.id));
    }

    public restore() {
      this.$store.dispatch(RESTORE_USER)
        .then(() => this.$store.dispatch(FETCH_USER, this.$route.params.id));
    }
  }
